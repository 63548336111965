import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { content: {} },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: { content: {} },
    component: () => import('../views/About.vue')
  },
  {
    path: '/productionCenter',
    name: 'ProductionCenter',
    meta: { content: {} },
    component: () => import('../views/ProductionCenter.vue')
  },
  {
    path: '/serverExample',
    name: 'ServerExample',
    meta: { content: {} },
    component: () => import('../views/ServerExample.vue')
  },
  {
    path: '/detail',
    name: 'Detail',
    meta: { content: {} },
    component: () => import('../views/Detail.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0;
  next();
});

export default router
