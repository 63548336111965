<template>
  <div id="app">
    <router-view> </router-view>

    <a v-show="btnFlag" @click="toTop" class="topBtn" href="javascript:void(0);"
      ><img :src="require('@/assets/images/phone/icon_top.png')"
    /></a>
  </div>
</template>
<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      scrollTop: 0,
      btnFlag: false,
    };
  },
  methods: {
    toTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style lang="less">
.topBtn {
  display: none;
}

@media screen and (max-width: 750px) {
  .topBtn {
    z-index: 120;
    // display: block;
    position: fixed;
    width: 38px;
    height: 38px;
    right: 10px;
    bottom: 18px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
* {
  box-sizing: border-box;
  font-family: PingFang SC;
}
html {
  font-size: 37.5px;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea,
menu {
  margin: 0;
  padding: 0;
}

header,
footer,
section,
article,
aside,
nav,
hgroup,
address,
figure,
figcaption,
menu,
details {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
  font-weight: normal;
}

html,
body,
fieldset,
img,
iframe,
abbr {
  border: 0;
}

img {
  width: 100%;
  vertical-align: bottom;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}

[hidefocus],
summary {
  outline: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  font-size: 100%;
}

sup,
sub {
  font-size: 83%;
}

pre,
code,
kbd,
samp {
  font-family: inherit;
}

q:before,
q:after {
  content: none;
}

textarea {
  overflow: auto;
  resize: none;
}

label,
summary {
  cursor: default;
}

a,
button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
em,
strong,
b {
  font-weight: bold;
}

del,
ins,
u,
s,
a,
a:hover {
  text-decoration: none;
}

body,
textarea,
input,
button,
select,
keygen,
legend {
  font: 12px/1.14 Microsoft YaHei, \5b8b\4f53;
  color: #333;
  outline: 0;
}

body {
  background: #fff;
}

a,
a:hover {
  color: #333;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: auto !important;
}
</style>
